// Import general.ts again (for correct bug on css order)
import "@ts/general.ts";

// Import fo.ts
import { WebcimesModal, axios, _t } from "@ts/fo.ts";

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function() {
    // Add to cart
    document.querySelectorAll("form[name=formAddCart]").forEach((el: HTMLFormElement) => {
        el.addEventListener("submit", function(e) {
            e.preventDefault();
            
            let formData = new FormData(this);
            let url = el.getAttribute("action")!;
            let redirect = el.getAttribute("data-redirect")!;
            axios.post(url, formData).then((response) => {
                if(response.data.success) {
                    new WebcimesModal({
                        bodyHtml: response.data.success,
                        buttonCancelHtml: _t("Rester sur la page"),
                        buttonConfirmHtml: _t("Accéder au panier"),
                        onConfirmButton: () => {
                            window.location.href = redirect
                        }
                    });

                    // Update nav cart count
                    let navCartCount = document.querySelector(".nav-top__cart-count") as HTMLElement;
                    let cartCount = parseInt(navCartCount.innerHTML);
                    navCartCount.innerHTML = (cartCount + 1).toString();
                }
                else {
                    new WebcimesModal({
                        bodyHtml: response.data.error
                    });
                }
            });
        });
    });

    // If paymentStatus is success, show success modal
    let paymentStatus = document.querySelector("main")?.getAttribute("data-payment-status");
    if(paymentStatus == "success") {
        new WebcimesModal({
            bodyHtml: _t("Le paiement a été effectué avec succès."),
            buttonConfirmHtml: _t("Fermer"),
        });
    }
    else if(paymentStatus == "error") {
        new WebcimesModal({
            bodyHtml: _t("Une erreur est survenue lors du paiement."),
            buttonConfirmHtml: _t("Fermer"),
        });
    }

    
});